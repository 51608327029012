
// import { Calendar } from "v-calendar";
// import moment from "moment";

import apiTicket from "../services/ticket";
import { openToast } from "../services/toast";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonBadge,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { refresh } from "ionicons/icons";

export default {
  name: "Tab3",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonBadge,
    IonIcon,
    IonFab,
    IonFabButton,
    // Calendar,
  },
  setup() {
    const router = useRouter();
    const tickets = ref([]);
    const loading = ref(false);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    const date = ref(new Date());
    const selectedDay = ref(new Date()); // Add state to store selected day

    const refCalendar = ref(null);

    const todayTicket = ref([]);
    const flagTodayTicket = ref(true); //setto a false quando clicco sul calendario e li nascondo

    /**
     *
     * Convert tickets array into attributes for calendar visualization
     * Assign key derived from original ticket.tickets_id field
     * Assign ticket element to attribute's customData property to access original ticket in template
     * Assign tickets_data_assegnazione as date to use in calendar visualization
     */
    // const setAttributes = computed(() => {
    //   return tickets.value.map((ticket) => ({
    //     key: `ticket.${ticket.tickets_id}`,
    //     highlight: {
    //       color: "blue",
    //       fillMode: "light",
    //     },
    //     dates: ticket.tickets_assign_date,
    //     customData: ticket,
    //   }));
    // });
    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    // const dayClicked = (day) => {
    //   flagTodayTicket.value = false; //hide today ticket load on first time when clicked on Ticket tab
    //   selectedDay.value = day;
    // };

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    const riferimentoCliente = computed(() => {
      return (ticket) => {
        if (ticket.customers_name && ticket.customers_last_name) {
          return `${ticket.customers_name} ${ticket.customers_last_name}`;
        } else if (ticket.customers_company) {
          return `${ticket.customers_company}`;
        } else {
          return " - ";
        }
      };
    });
    /**
     *
     * Print project code and customers address or " - " based on condition
     *
     */
    // const riferimentoImpianto = computed(() => {
    //   return (impianto) => {
    //     if (impianto.projects_codice && impianto.customers_address) {
    //       return `${impianto.projects_codice} - ${impianto.customers_address}`;
    //     } else {
    //       return " - ";
    //     }
    //   };
    // });

    /**
     *
     * Set correct background for ticket status
     *
     */
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //aperto
          className = "secondary";
        } else if (statusId == 2) {
          //in lavorazione
          className = "warning";
        } else if (statusId == 3) {
          //attesa rsposta
          className = "tertiary";
        } else if (statusId == 4) {
          //in pausa
          className = "danger";
        } else {
          //chiuso
          className = "success";
        }
        return className;
      };
    });

    /**
     *
     * Remove html tag from ticket description
     *
     */
    function parseHtmlEnteties(str) {
      if (str) {
        const strippedString = str.replace(/(<([^>]+)>)/gi, "");
        return strippedString.replace(
          /&#([0-9]{1,3});/gi,
          function (match, numStr) {
            const num = parseInt(numStr, 10); // read num as normal number
            return String.fromCharCode(num);
          }
        );
      }
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      const dateFormatted = new Date(date);
      return `${dateFormatted.getDate()}/${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getFullYear()}`;
    }

    /**
     *
     * Passing id to retrive detail in Ticket Detail page
     *
     */
    function openDetail(id: string) {
      router.push(`tab3/${id}`);
    }

    /**
     *
     * Get only todays tickets to display when tab 3 is loading
     * Hide when click on calendar
     *
     */
    // function getTodayTicket(tickets) {
    //   tickets.forEach((ticket) => {
    //     if (moment.isDate(new Date(ticket.tickets_assign_date))) {
    //       const todayDate = moment(date.value).format("DD/MM/YYYY");
    //       const ticketDate = moment(ticket.tickets_assign_date).format(
    //         "DD/MM/YYYY"
    //       );
    //       if (ticketDate === todayDate) {
    //         todayTicket.value.push(ticket);
    //       }
    //     }
    //   });
    // }

    function getTickets() {
      loading.value = true;
      /* GET TICKET OPENED FOR THE USER */
      apiTicket
        .getTickets(userID)
        .then((response) => {
          tickets.value = response;
        //   selectedDay.value = null;
          // FIX PER MOSTRATE TICKET DI OGGI --> Mi prendo i ticket di oggi e li mostro, al click su un giorno del calendario devo nasconderli e mostrare quelli "veri del calendario"
        //   todayTicket.value.length = 0;
        //   if (todayTicket.value.length === 0) {
        //     getTodayTicket(tickets.value);
        //   }
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei ticket", "danger");
          //console.error(error);
        })
        .finally(() => {
          //console.log(setAttributes(tickets.value));
          loading.value = false;
        //   flagTodayTicket.value = true;
          //console.log("fine");
        });
    }

    onMounted(() => {
      //console.log(refCalendar.value.$theme);
      getTickets();
    });

    return {
      loading,
      tickets,
      riferimentoCliente,
      // riferimentoImpianto,
      dateFormat,
      openDetail,
      statoTicket,
      date,
    //   setAttributes,
    //   dayClicked,
      selectedDay,
      parseHtmlEnteties,
      //...
      refCalendar,
      refresh,
      getTickets,
      //flag per ticket di oggi, se true mostro
      flagTodayTicket,
      todayTicket,
    };
  },
};
